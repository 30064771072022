ul{
    list-style-type:none;
    padding-left:0.5em;
    padding-right:0.5em;
    margin-left:0.5em;
    margin-right:0.5em;
}

.proj-card {
    width: 11cm;
    height: 11cm;
    background-color:var(--secondary-bg-color)!important;
}

/* .proj-card:hover {
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    scale: 1.1;
} */

.project-description-text{
    color:var(--main-text-color);
}

.proj-title {
    text-align: center;
    color:var(--accent-color-1)
}

.card-action-area {
    /* text-align: center; */
    display: flex;
    justify-content: center;
}

.project-image {

    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 10px;
    margin: auto;
    display: block;
    max-width:11cm;
    max-height:190px;

}
.project-image:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transform:scale(1.01);
    cursor:pointer;
}

.project-description {
    margin-top: 5px;
}

.project-tags{
    margin-top:1em;
    
}

.proj-tag{
    /* background-color: transparent;
    border: 1px solid var(--main-text-color);
    color: var(--main-text-color); */
    /* margin:0.25em; */
    background-color: transparent;
    border: 1px solid var(--main-text-color);
    border-radius: 10px;
    color: var(--main-text-color);
    padding:0.25em;
    margin-right: 5px;
}