.Initial__Screen {
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  overflow: hidden;
  /* font-family: 'Tahoma'; */
  /* font-family: 'Segoe UI'; */
  font-family: 'Roboto';
}

.welcomeMessage {
  text-align: center;
  position: relative;
  top: 20%;
  
}

.navDiv {
  /* width: 10%; */
  display: inline-block;
}

.welcome {
  border-radius: 4px;
  padding: 6px 10px;
  background-color: '#f0f0f0';
  color:#7b8390;
  font-size:48px;
  /* font-weight: bold; */
  margin-bottom: 4cm;
}

.nav_link {
  display: inline-block;
  /* width: 10%; */
  border-radius: 4px;
  padding: 6px 10px;
  color: '#545e6f';
  text-decoration: none;
  background-color: '#f0f0f0';
  font-weight: 500;
  /* margin-bottom: 30px; */
}