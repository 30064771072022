.App {
  font-family: 'Roboto';
}


:root {
  --main-bg-color: #abdbe3;
  --secondary-bg-color: #FFFFFF;
  --main-text-color: #000000;

  --accent-color-1: #30392b;
  /* --light: #EFF1F3;
  --dark: #080705; */
  --light: #FFFFFF;
  --dark: #000000;
}

html{
  background-color: var(--main-bg-color)!important;
}


.color-text{
  color:var(--main-text-color);
}


.color-bg{
  color:var(--main-bg-color);
}


.color-accent{
  color:var(--accent-color-1)
}