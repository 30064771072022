#research{
    /* background-color:  transparent; */
    margin-top: 2em;
    /* margin-bottom:7em; */
    border-radius:0.5em;
    margin-left: 30%;
    margin-right: 30%;
}

.research-header {
    text-align: center;
    padding-bottom: 1em;
    color:var(--main-text-color);
}