/* .MainPage {
    font-family: 'courier new';
} */

/* .background {
    z-index: 1;
    position: absolute;
    top: 20px;
} */

html{
    background-color: var(--main-bg-color)!important;
  }

body{
    background-color:var(--main-bg-color)!important;
    color:var(--main-text-color)!important;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.content {
    z-index: 2;
    position: relative;
    /* width: 50%; */
    /* text-align: center; */
    /* margin: auto; */
    
    
}

.introCard {
    justify-content: center;
    display: flex;
    margin-top: 4cm;
}

.threeCardGrid {
    justify-content: center;
    display: flex;
    padding-top: 5cm;
}

.to10xResearch {
    justify-content: right;
    display: flex;
    padding-right: 35px;
    padding-bottom: 75px;
    overflow-x: hidden;
}

.secondBackground {
    background-color: #212121;
    height:32cm
}

/* Solar System */
#canvas{
    margin:0 auto;
    display:block;
}


.outer_wrapper {
    position: absolute;
    width: 100%;
    height: 55vh;
    overflow: hidden;
  }
  
  .wrapper {
    position: absolute;
    height: calc(55vh - 100px);
    width: 100%;
    top: 0;
  }
  
  .ground {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background-color: rgb(1, 143, 96);
  }
  
  .cat {
    position: absolute;
    bottom: 65px;
    left: 100px;
    height: 30px;
    width: 60px;
    transition: 1.5s;
    transform-origin: center;
    background-color: transparent;
  }
  
  /* body */
  
  .body {
    position: absolute;
    height: 30px;
    width: 60px;
  }
  
  .face_left .body { 
    animation: turn_body_left forwards 0.5s;
  }
  
  @keyframes turn_body_left {
    0%,100% { transform: scale(1); }
    50% { transform: scale(0.5, 1); }
  }
  
  .face_right .body {
    animation: turn_body_right forwards 0.5s;
  }
  
  @keyframes turn_body_right {
    0%,100% { transform: scale(1); }
    50% { transform: scale(0.5, 1); }
  }
  
  /* head */
  .cat_head {
    position: absolute;
    height: 40px;
    width: 48px;
    right: -10px;
    top: -30px;
    transition: 0.5s;
    z-index: 50;
  }
  
  .first_pose .cat_head,
  .face_left .cat_head{ 
    right: 22px;
  }
  
  
  /* tail */
  .tail {
    position: absolute;
    top: -25px;
    height: 36px;
    width: 15px;
    animation: tail_motion forwards 2s;
    transform-origin: bottom right;
  }
  
  @keyframes tail_motion {
    0%,100% { 
      left: -5px;
      transform: rotate(0deg) scale(1); 
    }
    50% { 
      left: -10px;
      transform: rotate(-50deg) scale(-1,1); 
    }
  }
  
  .first_pose .tail ,
  .face_left .tail {
    left: 45px;
    animation: tail_motion_alt forwards 2s;
  }
  
  @keyframes tail_motion_alt {
    0%,100% { 
      left: 45px;
      transform: rotate(0deg) scale(1); 
    }
    50% { 
      left: 40px;
      transform: rotate(50deg) scale(-1,1); 
    }
  }
  
  
  
  /* legs */
  .leg {
    position: absolute;
    height: 20px;
    width: 10px;
    transform-origin: top center;
  }
  
  .front_legs,
  .back_legs {
    position: absolute;
    height: 30px;
    transition: 0.7s;
  }
  
  .front_legs {
    width: 30px;
    right: 0;
  }
  
  .back_legs {
    width: 25px;
    left: 0; 
  }
  
  .face_left .leg svg {
    transform: scale(-1,1);
  }
  
  .face_right .front_legs{ right: 0; }
  
  .first_pose .front_legs,
  .face_left .front_legs{ right: 30px; }
  
  .face_right .back_legs{ left: 0; }
  
  .first_pose .back_legs,
  .face_left .back_legs{ left: 35px; }
  
  .one,
  .three  {
    bottom: -15px;
    right: 0;
  }
  
  .two, 
  .four {
    bottom: -15px;
    left: 0px;
  }
  
  .one.walk, 
  .three.walk {
    animation: infinite 0.3s walk;
  }
  
  .two.walk, 
  .four.walk {
    animation: infinite 0.3s walk_alt;
  }
  
  @keyframes walk {
    0%,100% {transform: rotate(-10deg);}
    50% {transform: rotate(10deg);}
  }
  
  @keyframes walk_alt {
    0%,100% {transform: rotate(10deg);}
    50% {transform: rotate(-10deg);}
  }
  
  
  /* jump */
  .cat_wrapper {
    position: absolute;
    bottom: 0;
  }
  
  .cat_wrapper.jump .one { 
    animation: infinite 0.3s walk;
  }
  
  .cat_wrapper.jump .two { 
    animation: infinite 0.3s walk_alt;
  }
  
  .cat_wrapper.jump .three,
  .cat_wrapper.jump .four {
    animation: none;
  }
  
  .cat_wrapper.jump .cat.face_right .back_legs {
    transform-origin: center;
    transform: rotate(50deg);
  }
  
  .cat_wrapper.jump .cat.face_left .back_legs {
    transform-origin: center;
    transform: rotate(-50deg);
  }
  
  .cat_wrapper.jump .cat.face_right .front_legs {
    transform-origin: center;
    transform: rotate(-60deg);
  }
  
  .cat_wrapper.jump .cat.face_left .front_legs {
    transform-origin: center;
    transform: rotate(60deg);
  }
  
  .cat_wrapper.jump{
    animation: jump forwards 1s;
  }
  
  @keyframes jump {
    0%, 100%  {bottom: 0px;}
    50% {bottom: 200px;}
  }
  
  .jump .face_left{ 
    animation: forwards 0.5s body_stand_left;
    transform-origin: right bottom;
  }
  
  .jump .face_right{ 
    animation: forwards 0.5s body_stand_right;
    transform-origin: left bottom;
  }
  
  @keyframes body_stand_right {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(-45deg);}
  }
  
  @keyframes body_stand_left {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(45deg);}
  }
  
  svg {
    height: 100%;
    width: 100%;
  }
  
  polygon.eyes {
    fill: rgb(1, 143, 96);
  }
  
  polygon,
  path {
    fill: white;
  }