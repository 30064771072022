.textContainer {
    /* text-align: left; */
    /* float: left; */
    padding-left: 10px;
}

.intro-card {
    
    width: 20cm;
    height: 9.8cm;
    background-color:var(--secondary-bg-color)!important;
    color:var(--main-text-color)!important;
}